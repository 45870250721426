import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import TwoColumnLayout from "components/TwoColumnLayout/TwoColumnLayout"

const NewsArticleTemplate = (props) =>  {
    const data = props.data.wpNewsArticle
    const googleAdsSidebar = data.acfGoogleAdsSidebar
    return (
        <Layout 
            path={data.uri} 
            seo={data.seo} 
            hideSignup={data.acfFooterSettings.hideNewsletterSignUp}
            hideSocial={data.acfFooterSettings.hideSocialFollowUs}
        >
            <TwoColumnLayout 
                data={data} 
                googleAdsSidebar={googleAdsSidebar} 
                breadcrumbs={true} 
                pageTitle={data.title} 
            />
        </Layout>
    )
}

export const newsArticleQuery = graphql`
query($id: String!) {
    wpNewsArticle(id: {eq: $id}) {
        id
        title
        uri
        date(formatString: "MMMM DD, Y")
        nodeType
        seo {
            metaKeywords
            metaDesc
            title
            breadcrumbs {
                text
                url
            }
        }
        ...newsArticleTemplateQuery
        acfFooterSettings {
            hideNewsletterSignUp
            hideSocialFollowUs
        }
    }
}
`

export default NewsArticleTemplate